import { Button, Checkbox, Form, Input, InputNumber, Switch } from "antd";
import { AxiosError } from "axios";
import {
  FormLabel,
  ModalContainer,
  showNotification,
} from "components/elements";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { webRoutes } from "routes/web";
import useStores from "stores";
import { ErrorResponse, FormDataMatch } from "types";
import {
  filterExistingValues,
  handleAxiosError,
  handleRemoveSpaceInput,
} from "utils";
import { ModalSelectFile } from "./ModalSelectFile";

interface CommandProps {
  nameCmd: string;
}

export const ModalCommandMatch: React.FC<CommandProps> = observer(
  ({ nameCmd }) => {
    const { t } = useTranslation();
    const { parameterStore, commonStore } = useStores();
    const { toggleModalCommand, showModalCommand, showModalSelectFile } =
      commonStore;
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [form] = Form.useForm<FormDataMatch>();
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [currentInputName, setCurrentInputName] = useState<
      keyof FormDataMatch | null
    >(null);
    const navigate = useNavigate();
    const onSubmit = () => {
      form
        .validateFields()
        .then((values) => {
          setIsSubmit(true);
          parameterStore
            .runCommand("match", filterExistingValues(values))
            .then(() => {
              showNotification("notification.command.success", "success");
              navigate(`${webRoutes.dashboard.user.terminal}`);
              toggleModalCommand();
            })
            .catch((error) => {
              handleAxiosError(error as AxiosError<ErrorResponse>);
            })
            .finally(() => {
              setIsSubmit(false);
            });
        })
        .catch(() => {});
    };

    const handleClose = () => toggleModalCommand();

    const handleShowAdvanceParameter = () => setIsChecked(!isChecked);

    const handleOpenFile = <T extends keyof FormDataMatch>(nameInput: T) => {
      commonStore.toggleModalSelect();
      setCurrentInputName(nameInput);
    };

    const handleSelectFile = (value: string) => {
      const remotePath =
        process.env.REACT_APP_REMOTE_PATH_FILE || "/usr/src/app/mnt";
      const userPrefix = process.env.REACT_APP_USER_PATH_PATTERN || "user_";
      if (remotePath && value.includes(remotePath)) {
        value = value.replace(userPrefix ? remotePath + "/" : remotePath, "");
      }
      if (userPrefix) {
        const userPathPattern = new RegExp(`${userPrefix}\\w+\/`, "g");
        value = value.replace(userPathPattern, "");
      }
      if (currentInputName) {
        form.setFieldValue(currentInputName, value);
      }
      commonStore.toggleModalSelect();
    };
    return (
      <ModalContainer
        open={showModalCommand}
        title={`${t("command")} ${nameCmd}`}
        onCancel={handleClose}
        onOk={onSubmit}
        isSubmit={isSubmit}
        okText={"commons.run"}
      >
        <Form
          onFinish={onSubmit}
          layout="vertical"
          form={form}
          className="px-5"
        >
          <FormLabel text={t("basic-parameter")} />
          <Form.Item name="A" valuePropName="checked">
            <Checkbox>{t("params.match.fast-mode")}</Checkbox>
          </Form.Item>

          <Form.Item label={t("params.match.output-directory-name")} name="D">
            <Input
              type="text"
              placeholder="result"
              onChange={(e) => {
                handleRemoveSpaceInput(e, "D", form);
              }}
            />
          </Form.Item>

          <Form.Item label={t("params.match.index-files-base-name")} name="i">
            <Input
              type="text"
              placeholder="indexdir/hashtmp"
              onChange={(e) => {
                handleRemoveSpaceInput(e, "i", form);
              }}
            />
          </Form.Item>
          <Form.Item
            label={t("params.match.output-samfile-base-name")}
            name="s"
          >
            <Input
              type="text"
              placeholder="test"
              onChange={(e) => {
                handleRemoveSpaceInput(e, "s", form);
              }}
            />
          </Form.Item>

          <Form.Item name="S" valuePropName="checked">
            <Checkbox>{t("params.match.single-file")}</Checkbox>
          </Form.Item>
          <Button onClick={() => handleOpenFile("q")}>
            {t("commons.choose-file")}
          </Button>
          <Form.Item
            label={t("params.match.input-fq-files-base-name-1")}
            name="q"
          >
            <Input type="text" readOnly />
          </Form.Item>
          <Button onClick={() => handleOpenFile("Q")}>
            {t("commons.choose-file")}
          </Button>
          <Form.Item
            label={t("params.match.input-fq-files-base-name-2")}
            name="Q"
          >
            <Input type="text" readOnly />
          </Form.Item>
          <Form.Item label={t("params.match.number-of-threads")} name="t">
            <InputNumber precision={0} placeholder="16" />
          </Form.Item>
          <Form.Item label={<FormLabel text={t("advance-parameter")} />}>
            <Switch onChange={() => handleShowAdvanceParameter()} />
          </Form.Item>
          {isChecked && (
            <>
              <Form.Item name="a" valuePropName="checked">
                <Checkbox>
                  {t("params.match.additional-aassearch-options-checkbox")}
                </Checkbox>
              </Form.Item>
              <Form.Item name="b" valuePropName="checked">
                <Checkbox>{t("params.match.do-not-use-bqsr")}</Checkbox>
              </Form.Item>
              <Form.Item name="c" valuePropName="checked">
                <Checkbox>
                  {t("params.match.additional-swscore-options")}
                </Checkbox>
              </Form.Item>
              <Form.Item label={t("params.match.debug")} name="d">
                <InputNumber precision={0} placeholder="0" />
              </Form.Item>
              <Form.Item label={t("params.match.suffix-length")} name="l">
                <InputNumber precision={0} placeholder="4" />
              </Form.Item>
              <Form.Item
                label={t("params.match.number-of-reads-per-thread")}
                name="M"
              >
                <InputNumber precision={0} placeholder="500000" />
              </Form.Item>
              <Form.Item
                label={t("params.match.number-of-bases-per-file")}
                name="n"
              >
                <InputNumber precision={0} placeholder="5000000" />
              </Form.Item>

              <Form.Item
                label={t("params.match.number-of-reads-for-bqsr-calculation")}
                name="N"
              >
                <InputNumber precision={0} placeholder="250000" />
              </Form.Item>
              <Form.Item
                label={t("params.match.input-directory-name-1")}
                name="r"
              >
                <Input
                  type="text"
                  placeholder="tmp1"
                  onChange={(e) => {
                    handleRemoveSpaceInput(e, "r", form);
                  }}
                />
              </Form.Item>
              <Form.Item
                label={t("params.match.input-directory-name-2")}
                name="R"
              >
                <Input
                  type="text"
                  placeholder="tmp2"
                  onChange={(e) => {
                    handleRemoveSpaceInput(e, "R", form);
                  }}
                />
              </Form.Item>
              <Form.Item
                label={t("params.match.wait-seconds-before-cswscore")}
                name="T"
              >
                <InputNumber precision={0} placeholder="200" />
              </Form.Item>

              <Form.Item label={t("params.match.verbose")} name="v">
                <InputNumber precision={0} placeholder="0" />
              </Form.Item>
              <Form.Item
                label={t("params.match.work-directory-name-for-aassearch")}
                name="w"
              >
                <Input
                  type="text"
                  placeholder="tmp1"
                  onChange={(e) => {
                    handleRemoveSpaceInput(e, "w", form);
                  }}
                />
              </Form.Item>
              <Form.Item
                label={t("params.match.work-file-base-name-for-aassearch")}
                name="W"
              >
                <Input
                  type="text"
                  placeholder="test"
                  onChange={(e) => {
                    handleRemoveSpaceInput(e, "W", form);
                  }}
                />
              </Form.Item>
              <Form.Item
                label={t("params.match.executes-commands-in-bits")}
                name="x"
              >
                <InputNumber precision={0} placeholder="65535" />
              </Form.Item>
              <Form.Item label={t("params.match.fastmode-level")} name="X">
                <InputNumber precision={0} placeholder="3" />
              </Form.Item>
              <Form.Item
                label={t("params.match.dry-run")}
                name="y"
                valuePropName="checked"
              >
                <Checkbox>{t("params.match.dry-run")}</Checkbox>
              </Form.Item>
              <Form.Item
                name="extraParameter"
                label={t("params.prepare.extra-parameter")}
              >
                <Input type="text" placeholder="" />
              </Form.Item>
            </>
          )}
        </Form>
        {showModalSelectFile && (
          <ModalSelectFile handleSelectFile={handleSelectFile} />
        )}
      </ModalContainer>
    );
  }
);
