export type Language = {
    key: string;
    label: string;
    content: string
};

export type limitOptionProps = {
    value: number,
    label: string
};

export enum EnumRoleTypes {
    ADMIN = "admin",
    USER = "user"
}
export interface routerType {
    path: string;
    element?: JSX.Element;
    errorElement?: JSX.Element
}

export enum EnumLanguage {
    JP = "jp",
    EN = "en"
}

export enum EnumSidebarItems {
    DASHBOARD = 'dashboard',
    USER = 'user',
    PROCESS = 'process',
    TERMINAL = 'terminal',
    LICENSE = 'license',
}

export enum EnumProfileItem {
    PROFILE = 'profile',
    LOGOUT = 'logout',
}

export interface ProfileMenuItem {
    key: string;
    label: string;
    icon: React.ReactNode;
    path: string ;
    textColor: string;
}

export enum EnumCommands {
    PREPARE = 'aas-prepare',
    MATCH = 'aas-match',
    VCALL = 'aas-vcall',
}

export interface Pagination{
    page: number;
    limit: number;
    total: number;
    totalPages: number;
    from: number;
    to: number;
}

export interface dataRegisterLicense {
    organizationName: string;
    licenseKey: string;
}

export enum EnumFolderPath{
    DATA = 'data',
    RESULT = 'work/result',
}