import {
  UpCircleOutlined,
  EyeOutlined,
  AlignRightOutlined,
} from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useStores from "stores";
import {
  HttpStatusCode,
  EnumRoleTypes,
  EnumCommands,
  ErrorResponse,
} from "types";
import {
  CustomTitle,
  LoadingSpinner,
  showNotification,
} from "components/elements";
import { useSetPageTitle } from "hook";
import clsx from "clsx";
import ModalRunCommand from "./Modal/ModalRunCommand";
import { ModalSelectFile, ModalUploadFile } from "./Modal";
import { handleAxiosError } from "utils";
import { AxiosError } from "axios";
import { MenuProps } from "antd/lib";

const commands: EnumCommands[] = [
  EnumCommands.PREPARE,
  EnumCommands.MATCH,
  EnumCommands.VCALL,
];

export type typeFileExtension = {
  extension: string;
  number: number;
};

export const UserDashboard: React.FC = observer(() => {
  const { commonStore, userStore, containerStore } = useStores();
  const { getContainer, startContainer, stopContainer } = containerStore;
  const { currentUser } = userStore;
  const {
    toggleModalCommand,
    showModalCommand,
    toggleModalUploadFile,
    showModalUploadFile,
    showModalSelectFile,
    toggleModalSelect,
  } = commonStore;
  const [containerCheck, setContainerCheck] = useState<boolean>(false);
  const [nameCmd, setNameCmd] = useState<EnumCommands>(EnumCommands.PREPARE);
  const [isStarting, setIsStarting] = useState<boolean>(false);
  const [isStopping, setIsStopping] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [typeUpload, setTypeUpload] = useState<string>("");
  const [validateExtensionFile, setValidateExtensionFile] = useState<string[]>(
    []
  );
  const { t } = useTranslation();
  useSetPageTitle("dashboard");

  useEffect(() => {
    const fetchContainer = () => {
      setLoading(true);
      if (userStore.currentUser?.role !== EnumRoleTypes.ADMIN) {
        getContainer()
          .then((res) => {
            setContainerCheck(!!res.container);
          })
          .catch((error) => {
            handleAxiosError(error as AxiosError<ErrorResponse>);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };
    if (currentUser) {
      fetchContainer();
    }
  }, []);

  const handleStartContainer = () => {
    setIsStarting(true);
    startContainer()
      .then((res) => {
        const success = res.statusCode === HttpStatusCode.OK;
        success && showNotification("container-run-success", "success");
        setContainerCheck(success);
      })
      .catch((error) => {
        handleAxiosError(error as AxiosError<ErrorResponse>);
      })
      .finally(() => {
        setIsStarting(false);
      });
  };

  const handleStopContainer = () => {
    const userID = userStore.currentUser?._id || "";
    setIsStopping(true);
    stopContainer(userID)
      .then((res) => {
        const success = res.statusCode === HttpStatusCode.OK;
        success && showNotification("container-stop-success", "success");
        setContainerCheck(!success);
      })
      .catch((error) => {
        handleAxiosError(error as AxiosError<ErrorResponse>);
      })
      .finally(() => {
        setIsStopping(false);
      });
  };

  const handleStartCommand = (item: EnumCommands) => {
    if (containerCheck) {
      setNameCmd(item);
      toggleModalCommand();
    } else {
      showNotification(`${t("start-container-before-run-command")}`, "warning");
    }
  };
  const handleShowModalUploadFile = (
    acceptFileExtension: string[],
    typeFileUpdate?: string
  ) => {
    toggleModalUploadFile();
    setValidateExtensionFile(acceptFileExtension);
    setTypeUpload(typeFileUpdate || "");
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Button
          disabled={isStarting || isStopping}
          onClick={() => toggleModalSelect()}
          className="bg-darkBlue-200 w-full"
        >
          {t("view-uploaded-file")}
          <EyeOutlined className="max-w-7 max-h-7" />
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          disabled={isStarting || isStopping}
          onClick={() => {
            handleShowModalUploadFile([]);
          }}
          className="bg-darkBlue-200 w-full"
        >
          {t("upload-file")}
          <UpCircleOutlined className="max-w-7 max-h-7 " />
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          disabled={isStarting || isStopping}
          onClick={() => {
            handleShowModalUploadFile([".fa", ".fa.gz"], "gnome");
          }}
          className="bg-darkBlue-200 w-full"
        >
          {t("upload-gnome-file")}
          <UpCircleOutlined className="max-w-7 max-h-7" />
        </Button>
      ),
    },
  ];
  return (
    <>
      <div className="pt-20 space-y-6">
        <div className="flex gap-y-2 justify-between sm:items-center items-start">
          <CustomTitle text={t("command-list")} />
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
            placement="bottomRight"
            arrow
            className="bg-darkBlue-200 text-white md:hidden flex items-center justify-center"
          >
            <Button>
              <AlignRightOutlined className="max-w-7 max-h-7" />
            </Button>
          </Dropdown>

          <div className="hidden gap-x-3 md:flex">
            <Button
              disabled={isStarting || isStopping}
              onClick={() => toggleModalSelect()}
              className="bg-darkBlue-200 text-white"
            >
              {t("manage-uploaded-file")}
              <EyeOutlined className='"max-w-7 max-h-7' />
            </Button>
            <Button
              disabled={isStarting || isStopping}
              onClick={() => {
                handleShowModalUploadFile([]);
              }}
              className="bg-darkBlue-200 text-white"
            >
              {t("upload-file")}
              <UpCircleOutlined className='"max-w-7 max-h-7' />
            </Button>
            <Button
              disabled={isStarting || isStopping}
              onClick={() => {
                handleShowModalUploadFile([".fa", ".fa.gz"], "gnome");
              }}
              className=" bg-darkBlue-200 text-white "
            >
              {t("upload-gnome-file")}
              <UpCircleOutlined className='"max-w-7 max-h-7' />
            </Button>
          </div>
        </div>

        <div className="space-y-5">
          <Button
            onClick={handleStartContainer}
            disabled={containerCheck}
            loading={isStarting}
            size="large"
          >
            {t("commons.start")}
          </Button>
          {commands.map((item) => (
            <div
              onClick={() => handleStartCommand(item)}
              key={item}
              className={clsx(
                "text-darkBlue-200 h-auto flex gap-x-2 flex-wrap sm:justify-between sm:gap-y-0 gap-y-2 justify-center items-center font-normal rounded-lg py-5 px-5 border border-darkBlue-200",
                {
                  "bg-blueGrey-100 cursor-pointer": containerCheck,
                  "bg-gray-100 cursor-not-allowed": !containerCheck,
                }
              )}
            >
              {item}
            </div>
          ))}
          <Button
            onClick={handleStopContainer}
            disabled={!containerCheck}
            loading={isStopping}
            danger
            type="primary"
            size="large"
          >
            {t("commons.stop")}
          </Button>
        </div>
      </div>
      {loading && <LoadingSpinner />}
      {showModalCommand && <ModalRunCommand nameCmd={nameCmd} />}
      {showModalUploadFile && (
        <ModalUploadFile
          validateExtensionFile={validateExtensionFile}
          type={typeUpload}
        />
      )}
      {showModalSelectFile && <ModalSelectFile isDelete={true} />}
    </>
  );
});
