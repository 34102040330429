import {
  DeleteOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import iconFile from "assets/icon/iconFile.png";
import useStores from "stores";
import { ModalContainer, showNotification } from "components/elements";
import { formatFileSize, handleAxiosError } from "utils";
import { AxiosError } from "axios";
import { EnumFolderPath, ErrorResponse } from "types";

interface CommandProps {
  handleSelectFile?: (name: string) => void;
  isDelete?: boolean;
  folderPath?: string;
  // fileType: string
}

export const ModalSelectFile: React.FC<CommandProps> = observer(
  ({
    handleSelectFile,
    isDelete = false,
    folderPath = EnumFolderPath.DATA,
  }) => {
    const { t } = useTranslation();
    const { commonStore, parameterStore, userStore } = useStores();
    const { dataSearchFile, searchFileUpload, loadFiles, deleteFile } =
      parameterStore;
    const { toggleModalSelect, showModalSelectFile } = commonStore;
    const { currentUser } = userStore;
    const [isLoading, setIsLoading] = useState(false);
    const [loadingFile, setLoadingFile] = useState<string | null>(null);
    const searchRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const fetchData = () => {
        setIsLoading(true);
        loadFiles(folderPath)
          .catch((error) => {
            handleAxiosError(error as AxiosError<ErrorResponse>);
          })
          .finally(() => {
            setIsLoading(false);
          });
      };
      currentUser && fetchData();
    }, []);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      searchFileUpload(value);
    };
    const handleDeleteFile = (filePath: string) => {
      setLoadingFile(filePath);
      deleteFile(filePath)
        .then((res: any) => {
          showNotification(
            res.data.message || t("delete-file-successfully"),
            "success"
          );
        })
        .catch((error) => {
          handleAxiosError(error as AxiosError<ErrorResponse>);
        })
        .finally(() => {
          setLoadingFile(null);
        });
    };
    return (
      <ModalContainer
        title={t(
          folderPath === EnumFolderPath.DATA
            ? "manage-uploaded-file"
            : "manage-result-file"
        )}
        width={900}
        onCancel={() => toggleModalSelect()}
        open={showModalSelectFile}
        footer={false}
        hasScrollBody={false}
      >
        <div className=" flex justify-end">
          <div
            ref={searchRef}
            className="relative w-full bg-white border mb-2 p-2 h-auto flex rounded-lg"
          >
            <div className="h-7 min-w-7 flex justify-center items-center">
              <SearchOutlined className="text-lg" />
            </div>
            <input
              type="text"
              className="h-full flex-1 outline-none ps-2"
              onChange={handleSearch}
            />
          </div>
        </div>
        <div
          className={`relative z-30 overflow-y-auto customScroll max-h-[calc(100vh-30rem)]`}
        >
          {isLoading ? (
            <div>{t("status.loading")}</div>
          ) : dataSearchFile.length === 0 ? (
            <div className="opacity-50">{t("file-not-found")}</div>
          ) : (
            dataSearchFile.map((item, index) => (
              <div
                onClick={() => handleSelectFile && handleSelectFile(item.path)}
                className={`flex items-center gap-x-1 justify-between relative z-40 mb-2 group w-full ${
                  !isDelete && "hover:bg-blue-50 cursor-pointer"
                }`}
                key={index}
              >
                <div className="flex items-center gap-x-1 w-[94%]">
                  <div className="">
                    <img src={iconFile} className="max-w-7" alt="file-image" />
                  </div>
                  <div className="flex items-center gap-x-1 w-[calc(100%-1.75rem)] whitespace-nowrap">
                    <div className=" overflow-hidden text-ellipsis">
                      {item?.name}
                    </div>
                    <span className="font-bold">{`(${formatFileSize(
                      item?.size
                    )})`}</span>
                  </div>
                </div>
                {isDelete &&
                  (loadingFile === item?.path ? (
                    <LoadingOutlined className="max-w-4 max-h-4 text-darkBlue-200" />
                  ) : (
                    <DeleteOutlined
                      className="max-w-4 max-h-4 text-red-500 cursor-pointer hover:bg-blue-50"
                      onClick={() => handleDeleteFile(item?.path)}
                    />
                  ))}
              </div>
            ))
          )}
        </div>
      </ModalContainer>
    );
  }
);
