import { makeAutoObservable } from "mobx";
import { ParameterRequest } from "../request";
import axios, { AxiosError, AxiosProgressEvent } from 'axios';
import { CommandApiResponse, ErrorResponse, file, FormDataMatch, FormDataPrepare, FormDataVCall, HttpStatusCode } from "types";
import { handleAxiosError } from "utils";

class ParameterStore {
    fileServer: file[] = [];
    dataSearchFile: file[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    loadFiles = async (folderPath: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await ParameterRequest.getFolderByName(folderPath);
                if (res.data.statusCode === HttpStatusCode.OK && res.data.data) {
                    this.fileServer = res.data.data;
                    this.dataSearchFile = res.data.data;
                    resolve(res.data)
                }else{
                    reject(res.data)
                }
            } catch (error) {
                reject(error)
                handleAxiosError(error as AxiosError<ErrorResponse>)
            }
        })
    }
    
    async runCommand(name: string, data: FormDataPrepare | FormDataMatch | FormDataVCall): Promise<CommandApiResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await ParameterRequest.runCommand(name, data);
                if (res.data.statusCode === HttpStatusCode.OK && (res.data.message || res.data.command)) {
                    resolve(res.data);
                }else{
                    reject(res.data)
                }
            } catch (error) {
                reject(error)
            }
        })
    }

    uploadFile(isShared: boolean, type: string, file: File, userName: string, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void, signal?: AbortSignal): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await ParameterRequest.uploadFile(isShared, type, file, userName, onUploadProgress, signal)
                if (res.data.statusCode === HttpStatusCode.CREATED && res.data.data) {
                    this.fileServer.push(res.data.data);
                }
                resolve(res.data);
            } catch (error) {
                if (axios.isCancel(error)) {
                    return
                }
                    handleAxiosError(error as AxiosError<ErrorResponse>)
                    reject(error)
            }
        });
    }
    
    searchFileUpload = (search: string) => {
        const resultSearch = this.fileServer?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
        this.dataSearchFile = resultSearch
    }

    deleteFile = (filePath: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await ParameterRequest.deleteFile(filePath);
                if (res.data.statusCode === HttpStatusCode.OK && res.data.data) {
                    this.dataSearchFile = this.dataSearchFile.filter((item) => item.path !== filePath);
                    resolve(res.data)
                }else{
                    reject(res.data)
                }
            } catch (error) {
                reject(error)
                console.log(error)
            }
        })
    }
}

const parameterStore = new ParameterStore();
export default parameterStore;
