import { Button, Checkbox, Form, Input, InputNumber, Switch } from "antd";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { webRoutes } from "routes/web";
import useStores from "stores";
import { EnumCommands, ErrorResponse, FormDataPrepare } from "types";
import {
  FormLabel,
  ModalContainer,
  showNotification,
} from "components/elements";
import {
  filterExistingValues,
  handleAxiosError,
  handleRemoveSpaceInput,
} from "utils";
import { ModalSelectFile } from "./ModalSelectFile";
import { AxiosError } from "axios";

interface CommandProps {
  nameCmd: EnumCommands;
}

export const ModalCommandPrepare: React.FC<CommandProps> = observer(
  ({ nameCmd }) => {
    const { t } = useTranslation();
    const { commonStore, parameterStore } = useStores();
    const { toggleModalCommand, showModalCommand, showModalSelectFile } =
      commonStore;
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [form] = Form.useForm<FormDataPrepare>();
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [currentInputName, setCurrentInputName] = useState<
      keyof FormDataPrepare | null
    >(null);
    const navigate = useNavigate();

    const handleOpenFile = <T extends keyof FormDataPrepare>(nameInput: T) => {
      commonStore.toggleModalSelect();
      setCurrentInputName(nameInput);
    };

    const handleSelectFile = (value: string) => {
      const remotePath =
        process.env.REACT_APP_REMOTE_PATH_FILE || "/usr/src/app";
      const userPrefix = process.env.REACT_APP_USER_PATH_PATTERN || "user_";

      if (remotePath && value.includes(remotePath)) {
        value = value.replace(remotePath, "");
      }

      if (userPrefix) {
        const userPathPattern = new RegExp(`${userPrefix}\\w+\/`, "g");
        value = value.replace(userPathPattern, "");
      }

      if (currentInputName) {
        form.setFieldValue(currentInputName, value);
      }
      commonStore.toggleModalSelect();
    };

    const onSubmit = () => {
      form
        .validateFields()
        .then((values) => {
          setIsSubmit(true);
          parameterStore
            .runCommand("prepare", filterExistingValues(values))
            .then(() => {
              showNotification("notification.command.success", "success");
              navigate(`${webRoutes.dashboard.user.terminal}`);
              toggleModalCommand();
            })
            .catch((error) => {
              handleAxiosError(error as AxiosError<ErrorResponse>);
            })
            .finally(() => {
              setIsSubmit(false);
            });
        })
        .catch(() => {});
    };

    const handleClose = () => toggleModalCommand();
    const handleShowAdvanceParameter = () => setIsChecked(!isChecked);
    return (
      <div>
        <ModalContainer
          open={showModalCommand}
          title={`${t("command")} ${nameCmd}`}
          onCancel={handleClose}
          onOk={onSubmit}
          isSubmit={isSubmit}
          okText={"commons.run"}
        >
          <Form
            onFinish={onSubmit}
            form={form}
            layout="vertical"
            className="px-5"
          >
            <div>
              <div className="space-y-2">
                <FormLabel text={t("basic-parameter")} />
                <Button onClick={() => handleOpenFile("a")}>
                  {t("commons.choose-file")}
                </Button>
                <Form.Item
                  label={t("params.prepare.input-vcf-file-for-vcf-annotation")}
                  name="a"
                  rules={[
                    {
                      required: true,
                      message: t("validate.this-field-is-required"),
                    },
                  ]}
                  required
                >
                  <Input readOnly />
                </Form.Item>
                <Button onClick={() => handleOpenFile("f")}>
                  {t("commons.choose-file")}
                </Button>
                <Form.Item
                  label={t("params.prepare.input-fasta-file")}
                  name="f"
                  rules={[
                    {
                      required: true,
                      message: t("validate.this-field-is-required"),
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="o"
                  label={t("params.prepare.output-index-files-base-name")}
                >
                  <Input
                    placeholder="hashtmp"
                    onChange={(e) => handleRemoveSpaceInput(e, "o", form)}
                  />
                </Form.Item>
                <Form.Item
                  name="s"
                  label={t("params.prepare.output-dbsnp-files-base-name")}
                >
                  <Input
                    placeholder="dbsnp"
                    onChange={(e) => {
                      handleRemoveSpaceInput(e, "s", form);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="D"
                  label={t("params.prepare.output-directory-name")}
                >
                  <Input
                    placeholder="indexdir"
                    onChange={(e) => {
                      form.setFieldsValue({ D: e.target.value });
                      handleRemoveSpaceInput(e, "D", form);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="extraParameter"
                  label={t("params.prepare.extra-parameter")}
                >
                  <Input type="text" placeholder="" />
                </Form.Item>

                <Form.Item label={<FormLabel text={t("advance-parameter")} />}>
                  <Switch onChange={() => handleShowAdvanceParameter()} />
                </Form.Item>
                {isChecked && (
                  <>
                    <Form.Item
                      label={t("params.prepare.number-of-bases-per-vcf")}
                      name="b"
                    >
                      <InputNumber precision={0} placeholder="500000" />
                    </Form.Item>
                    <Form.Item name="d" label={t("params.prepare.debug")}>
                      <InputNumber precision={0} placeholder="0" />
                    </Form.Item>
                    <Form.Item
                      name="l"
                      label={t("params.prepare.suffix-length")}
                    >
                      <InputNumber precision={0} placeholder="4" />
                    </Form.Item>
                    <Button
                      onClick={() => handleOpenFile("Q")}
                      className="mb-3"
                    >
                      {t("commons.choose-file")}
                    </Button>
                    <Form.Item
                      label={t("params.prepare.input-vcf-file-for-calibration")}
                      name="Q"
                    >
                      <Input readOnly />
                    </Form.Item>
                    <Form.Item name="v" label={t("params.prepare.verbose")}>
                      <InputNumber precision={0} placeholder="0" />
                    </Form.Item>
                    <Form.Item
                      name="x"
                      label={t("params.prepare.executes-commands-in-bits")}
                    >
                      <InputNumber precision={0} placeholder="65535" />
                    </Form.Item>
                    <Form.Item name="r" valuePropName="checked">
                      <Checkbox>{t("params.prepare.dry-run")}</Checkbox>
                    </Form.Item>
                  </>
                )}
              </div>
            </div>
          </Form>
          {showModalSelectFile && (
            <ModalSelectFile handleSelectFile={handleSelectFile} />
          )}
        </ModalContainer>
      </div>
    );
  }
);
